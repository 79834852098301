import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I veckans avsnitt bjuder vi på mycket Trevligheter. Firefox släpper ny version, en ny standard för sexleksaker kan revolutionera marknaden. Rykande färska versioner av PiHole och Inkscape med många trevliga features.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Firefox 76: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/76.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/76.0/releasenotes/`}</a></li>
      <li parentName="ul">{`Open-source standard för sexleksaker: `}<a parentName="li" {...{
          "href": "https://buttplug.io/"
        }}>{`https://buttplug.io/`}</a></li>
      <li parentName="ul">{`Pi-hole v5.0: `}<a parentName="li" {...{
          "href": "https://pi-hole.net/2020/05/10/pi-hole-v5-0-is-here/"
        }}>{`https://pi-hole.net/2020/05/10/pi-hole-v5-0-is-here/`}</a></li>
      <li parentName="ul">{`Inkscape 1.0 på riktigt: `}<a parentName="li" {...{
          "href": "https://inkscape.org/news/2020/05/04/introducing-inkscape-10/"
        }}>{`https://inkscape.org/news/2020/05/04/introducing-inkscape-10/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Wink vill ha pengar: `}<a parentName="li" {...{
          "href": "https://blog.wink.com/wink-blog/2020/5/6/introducing-wink-subscription"
        }}>{`https://blog.wink.com/wink-blog/2020/5/6/introducing-wink-subscription`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Peek: `}<a parentName="li" {...{
          "href": "https://github.com/phw/peek"
        }}>{`https://github.com/phw/peek`}</a></li>
      <li parentName="ul">{`Whoogle search: `}<a parentName="li" {...{
          "href": "https://github.com/benbusby/whoogle-search"
        }}>{`https://github.com/benbusby/whoogle-search`}</a>{` `}<a parentName="li" {...{
          "href": "https://trevlig-whoogle.herokuapp.com/"
        }}>{`https://trevlig-whoogle.herokuapp.com/`}</a>{` `}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Sebs Linux-äventyr`}</h3>
    <ul>
      <li parentName="ul">{`zsh är amazing. plugs "z" och "auto-complete"`}<ul parentName="li">
          <li parentName="ul">{`manage configs: `}<a parentName="li" {...{
              "href": "https://github.com/ohmyzsh/ohmyzsh"
            }}>{`https://github.com/ohmyzsh/ohmyzsh`}</a></li>
          <li parentName="ul">{`theme: `}<a parentName="li" {...{
              "href": "https://github.com/romkatv/powerlevel10k"
            }}>{`https://github.com/romkatv/powerlevel10k`}</a></li>
        </ul></li>
      <li parentName="ul">{`kröhnkite tiling window manager (`}<a parentName="li" {...{
          "href": "https://github.com/esjeon/krohnkite"
        }}>{`https://github.com/esjeon/krohnkite`}</a>{`)`}<ul parentName="li">
          <li parentName="ul">{`borders på windows väldigt meckigt`}</li>
        </ul></li>
    </ul>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Seb har installerat neovim`}<ul parentName="li">
          <li parentName="ul">{`är ganska nöjd med plugins för tillfället`}</li>
        </ul></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      